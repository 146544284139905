@import '../color';

#NavBar {
  position: absolute;
  top:0;
  left:0;
  right: 0;
  header {
    height: 85px;
    display: flex;
    align-items: center;
    .logoColumn {
      margin-right: 35px;
      img {
        margin-left: 10px;
        width: 81px;
        height: 62px;
      }
    }
    .link {
      display: inline-block;
      font-size: 12px;
      color: $font-color;
      text-decoration: none;
      position: relative;
      &:after {
        content: "";
        background-color: white;
        height: 1px;
        width: 0;
        position: absolute;
        bottom: -5px;
        left: -5%;
        transition-duration: 200ms;
        transition-property: all;
      }
      &:hover:after {
        width: 110%;
      }
    }
    .menu {
      flex-grow: 1;
      @media screen and (max-width: 780px) {
        visibility: hidden;
        flex-grow: 0;
        width: 0px;
      }
      a {
        margin-right: 35px;
      }
    }
    .callToAction {
      width: 150px;
      text-align: right;
      padding-right: 32px;
      @media screen and (max-width: 780px) {
        width: 100%;
      }
    }
  }
}
