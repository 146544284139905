@import "../color.scss";

.DetailsColumn {
  &__title {
    color: $secondary;
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 1.75em;
    @media screen and (max-width: 800px) {
      margin-bottom: 1.5em;
    }
  }
  border-bottom: 1px solid $secondary;
  padding: 0 32px;
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  max-width: 900px;
}
