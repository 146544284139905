@import '../color';

#footer {
  background-color: black;
  padding: 12px;
  .objectRow {
    display: flex;
    flex-wrap: wrap;
    .object {
      padding: 32px;
      flex-shrink: 0;
      margin-right: 3em;
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 12px;
      }
      .socialisation {
        display: flex;
      }
      .icon {
        margin-right: 0.5em;
        width: 20px;
        height: 20px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        i {
          display: block;
          color: black;
        }
      }
      .link {
        display: inline-block;
        cursor: pointer;
        font-size: 12px;
        color: $font-color;
        text-decoration: none;
        position: relative;
        &:after {
          content: "";
          background-color: white;
          height: 1px;
          width: 0;
          position: absolute;
          bottom: -5px;
          left: -5%;
          transition-duration: 200ms;
          transition-property: all;
        }
        &:hover:after {
          width: 110%;
        }
      }
    }
  }
  .underlined {
    margin-top: 1em;
    border-top: 1px solid white;
    font-size: 10px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: block;
      padding: 12px;
    }
    :first-child {
      margin-right: 3em;
    }
  }
}
