@import "../color";

#Offers {
  background-color: $secondary;
  padding: 32px 32px 90px 32px;
  box-sizing: border-box;
  h2 {
    text-align: center;
    color: $cream;
  }
  .block {
    margin-top: 1em;
    &:not(:first-child) {
      margin-left: 2em;
    }
    box-sizing: border-box;
    width: 100%;
    max-width: 25em;
    background-color: $cream;
    border-radius: 5px;
    padding: 32px;
    padding-bottom: 0;
    padding-top: 59px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    text-align: center;
    flex-wrap: wrap;
    img {
      width: 122px;
      height: auto;
    }
    .title {
      font-weight: bold;
      margin-top: 35px;
      font-size: 32px;
    }
    .subText {
      max-width: 24em;
      margin-top: 9px;
      font-size: 13px;
    }
    .buttonRow {
      width: 100%;
      margin-top: 36px;
      display: flex;
      a {
        flex-grow: 1;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
      button {
        display: block;
        font-size: 18px;
        font-weight: 700;
        font-family: Courier;
        border: none;
        background-color: transparent;
        border-top: 1px solid $cream;
        width: 100%;
        height: 118px;
        outline: none;
        position: relative;
        cursor: pointer;
        &:after {
          content: "";
          background-color: black;
          height: 1px;
          width: 0;
          position: absolute;
          bottom: 37px;
          left: 25%;
          transition-duration: 200ms;
          transition-property: all;
        }
        &:hover:after {
          width: 50%;
        }
      }
    }
    &.bonus {
      max-width: none;
      background: $third;
      color: white;
      button {
        border-top: 1px solid white;
        color: white;
        &:after {
          background-color: white;
        }
      }
    }
  }
  .blockContainer {
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      .block {
        max-width: none;
        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }
    width: 100%;
    margin: auto;
    max-width: 52em;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
  }
}
