@import "../color";

@font-face {
  font-family: TitleFont;
  src: url(../Fonts/DK-MIDNIGHT-CHALKER/DKMidnightChalker.otf);
}

$background-start-width: 200%;

#Header {
  height: 40em;
  display: flex;
  background-image: url("../Pictures/backgrounds/background-header.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 32px;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
    .logoBig {
      max-width: 259.12px;
      height: auto;
    }
    h2 {
      font-family: TitleFont;
      font-size: 120px;
      margin: 0;
      margin-top: 25px;
    }
    h3 {
      margin-top: 25px;
      font-size: 22px;
    }
  }
}

.unzoom-bg-animation {
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-delay: 200ms;
  animation-name: unzoom;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  @keyframes unzoom {
    from {
      background-size: auto $background-start-width;
    }

    to {
      background-size: auto 100%;
    }
  }
}
