@import "../color.scss";

$responsiveSize: 800px;

.DetailsRow {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  color: $secondary;

  &__pictureContainer {
    position: relative;
    height: 25em;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &__text {
    margin-left: 32px;
    width: 30%;
    flex-shrink: 0;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 32px;
  }

  &__description {
  }

  &__link {
    color: $secondary;
    text-decoration: none;
    transition: opacity 200ms;
    &:hover {
      opacity: 0.6;
    }
  }

  @media screen and (max-width: $responsiveSize) {
    flex-direction: column;
    &__pictureContainer {
      position: relative;
      height: 10em;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    &__text {
      margin-left: 0;
      flex-shrink: 0;
      width: 100%;
    }
  }

  &--textLeft {
    flex-direction: row-reverse;
    @media screen and (max-width: $responsiveSize) {
      flex-direction: column;
    }

    .DetailsRow__text {
      margin-left: 0;
      text-align: right;
      margin-right: 32px;
    }
  }
}
