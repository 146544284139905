@import "../color";

#Manisfesto {
  background-color: $cream;
  color: $secondary;

  text-align: center;
  padding: 64px 32px 90px 32px;
  width: 100%;
  margin: auto;
  max-width: 900px;

  box-sizing: border-box;

  border-bottom: 1px solid $secondary;
}
