@import 'color';

body {
  background-color: $cream;
  font-family: Courier;
  color: $font-color;
  ::selection {
    background: $secondary;
  }
}

html {
  scroll-behavior: smooth;
}

.App {
  header {

  }
}

h2 {
  font-size: 49px;
  @media screen and (max-width: 700px){
    font-size: 23px;
  }
}

$background-start-width: 200%;
.unzoom-bg-animation {
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-delay: 200ms;
  animation-name: unzoom;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  @keyframes unzoom {
    from {
      background-size: $background-start-width $background-start-width;
    }

    to {
      background-size: 100% 100%;
    }
  }
}