@import '../color';

#About {
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 700px) {
    .hidable {
      visibility: hidden;
    }
  }
  .background {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 55%;
    @media screen and (max-width: 700px){
      visibility: hidden;
    }
    .picture1 {
      height: 100%;
      .parallax-inner {
        height: 100%;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 130%;
        margin-top: -8em;
      }
    }
  }
  height: 40em;
  @media screen and (max-width: 700px){
    height: 25em;
  }
  display: flex;
  background: $green-light;
  color: $secondary;
  padding: 25px;
  justify-content: center;
  align-items: center;
  text-align: center;
  .text {
    z-index: 1;
    max-width: 30em;
    @media screen and (min-width: 700px) {
      margin-left: 50%;
    }
  }
}
